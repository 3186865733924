// src/components/NavBar.js
import React from 'react';
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';

const NavBar = () => {

  const isMobile = () => {
    if(window.innerWidth <= 768){
      return true;
    }
  }

  return (
    <>
      {isMobile() ? <MobileNavBar /> : <DesktopNavBar />}
    </>
  );
};

export default NavBar;
