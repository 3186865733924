// src/pages/AboutUsPage.js
import React from 'react';
import { useState } from 'react';
import NavBar from '../components/NavBar';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './../styles/AboutUsPage.css'
import mapImage from './../assets/placeholder-map2.jpeg'

function ServiceArea() {
  const [zipCode, setZipCode] = useState('');
  const [serviceable, setServiceable] = useState(false);
  const [checkedForServiceable, setChecked] = useState(false);

  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value);
  };

  const checkServiceability = () => {

    const serviceableZipCodes = ['77581', '77588', '77584', '77089', '77075', '77549', '77048', '77546', '77578', '77061', 
    '77034', '77047', '77209', '77587', '77033', '77598', '77504', '77062', '77512', '77051', 
    '77017', '77087', '77502', '77045', '77059', '77501', '77508', '77021', '77505', '77545', 
    '77511', '77053', '77058', '77054', '77574', '77023', '77012', '77573', '77506', '77025', 
    '77030', '77004', '77503', '77011', '77547', '77507', '77085', '77035', '77583', '77005', 
    '77003', '77489', '77096', '77006', '77029', '77002', '77010', '77586', '77459', '77098', 
    '77402', '77536', '77565', '77202', '77204', '77206', '77207', '77208', '77210', '77212', 
    '77213', '77215', '77216', '77217', '77219', '77220', '77221', '77222', '77223', '77224', 
    '77225', '77226', '77227', '77228', '77229', '77230', '77231', '77233', '77234', '77235', 
    '77236', '77237', '77238', '77240', '77241', '77242', '77243', '77244', '77245', '77248', 
    '77249', '77251', '77252', '77253', '77254', '77255', '77256', '77257', '77258', '77259', 
    '77261', '77262', '77263', '77265', '77266', '77267', '77268', '77269', '77270', '77271', 
    '77272', '77273', '77274', '77275', '77277', '77279', '77280', '77282', '77284', '77287', 
    '77288', '77289', '77290', '77291', '77292', '77293', '77297', '77299', '77203', '77052', 
    '77401', '77046', '77001', '77020', '77201', '77081', '77539', '77517', '77019', '77071', 
    '77027', '77015', '77571', '77007', '77497', '77026', '77013', '77009', '77074', '77036', 
    '77056', '77477', '77031', '77572', '77510', '77057', '77518', '77481', '77028', '77008', 
    '77530', '77063', '77024', '77099', '77022', '77591', '77078', '77018', '77072', '77496', 
    '77479', '77016', '77042', '77577', '77093', '77055', '77487', '77478', '77049', '77411', 
    '77076', '77092', '77522', '77568', '77498', '77080', '77091', '77534', '77523', '77562', 
    '77082', '77563', '77077', '77083', '77050', '77079', '77043', '77037'];
    setChecked(true)
    setServiceable(serviceableZipCodes.includes(zipCode));
  };

  return (
    <div>
      <NavBar />
      <div className='about-us-content'>
        <div className='location-map'>
            <img src={mapImage} alt="Service Area Map" className='map-image' />
        </div>
        <div className='serviceability-check'>
          <h3>Do we service your area?</h3>
          <input
            type='text'
            placeholder='Enter your zip code'
            value={zipCode}
            onChange={handleZipCodeChange}
          />
          <button onClick={checkServiceability}>Check</button>
          {checkedForServiceable ? serviceable ? (<p><FaCheckCircle style={{color: 'green'}} /> We offer services in your area</p>) : (<p><FaTimesCircle style={{color: 'red'}} /> Sorry, we do not offer services in your area</p>) : <div></div>}
        
        </div>
      </div>
    </div>
  );
}

export default ServiceArea;
