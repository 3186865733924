// src/pages/AboutUsPage.js
import React from 'react';
import { useState } from 'react';
import NavBar from '../components/NavBar';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './../styles/AboutUsPage.css'


function AboutUsPage() {
  return (
    <div>
      <NavBar />
      <div className='about-us-content'>
        <h2>About Us</h2>
        <h3>Overview</h3>
        <p>Home health care provides medical skilled services that require a written order from a doctor. These services are typically for individuals recovering from an injury or surgery on a short-term basis. Patients often transition to home health care after a hospital or rehab stay and need extra support during their recovery.</p>
        <h3>Coordination of Care</h3>
        <p>Each patient is assigned a licensed Registered Nurse (RN) or clinical manager to monitor chronic or acute conditions. Our nursing and therapy staff regularly report to the patient's physicians, making necessary recommendations and changes. This ongoing communication ensures comprehensive coordination of care.</p>

        {/* Serviceability check section */}
        <div className='serviceability-check'>
          <a href='/location' className='service-btn'>Where We're Located</a>
        </div>
      </div>
    </div>
  );
}

export default AboutUsPage;
