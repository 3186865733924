// src/pages/LandingPage.js
import React from 'react';
import NavBar from '../components/NavBar';
import serviceImage1 from '../assets/senior-care-hm-pg-vrt.jpeg';
import serviceImage2 from '../assets/elderly-care2.jpg';
import serviceImage3 from '../assets/senior_rehab_1-2.jpg';
import serviceImage4 from '../assets/home-care-options-crop.jpg'
import "./../styles/LandingPage.css";

function LandingPage() {
  return (
    <div>
      <NavBar/>
      <div className='hero-section'>
        <h1>Home Care Options</h1>
        <p>An Agency You'd Want For Your Own Mom And Dad</p>
      </div>
      <section className='services-overview'>
        <h2>Our Services</h2>
        <div className='services-list'>
          <div className='service-item'>
            <img className='adjust-position' src={serviceImage1} alt='Skilled Medical Care' />
            <div className='overlay'>
              <div className='overlay-content'>
                <h3>Skilled Medical Care</h3>
                {/* <p>Professional nursing, therapy, and social work services.</p> */}
                <a href='/services/skilled-medical-care' className='service-btn'>Learn More</a>
              </div>
            </div>
          </div>
          <div className='service-item'>
            <img className='adjust-position' src={serviceImage4} alt='Skilled Medical Care' />
            <div className='overlay'>
              <div className='overlay-content'>
                <h3>Non-Medical Care</h3>
                <a href='/services/non-medical-care' className='service-btn'>Learn More</a>
              </div>
            </div>
          </div>
          <div className='service-item'>
            <img src={serviceImage2} alt='In-Home Services' />
            <div className='overlay'>
              <div className='overlay-content'>
                <h3>In-Home Services</h3>
                {/* <p>Certified visiting physicians, podiatry care, and mobile X-rays.</p> */}
                <a href='/services/in-home-services' className='service-btn'>Learn More</a>
              </div>
            </div>
          </div>
          <div className='service-item'>
            <img src={serviceImage3} alt='Specialized Programs' />
            <div className='overlay'>
              <div className='overlay-content'>
                <h3>Specialized Programs</h3>
                {/* <p>Customized care programs for various health conditions.</p> */}
                <a href='/services/specialized-programs' className='service-btn'>Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='intro-section'>
        <h2>About Us</h2>
        <p>At Home Care Options, we provide top-quality home health care services to help you recover and maintain your health in the comfort of your own home. Our skilled medical professionals are dedicated to offering personalized care tailored to your unique needs.</p>
        <a href='/about' className='learn-more-btn'>Learn More</a>
      </section>
      <footer className='footer'>
        <div className='contact-info'>
          <h2>Contact Information</h2>
          <p>Email: info@hcareoptions.com</p>
          <p>Phone: (832) 328-0179</p>
        </div>
        <p className="rights">© 2024 Home Care Options. All rights reserved.</p>
        <a href='/contact' className='contact-btn'>Contact Us</a>
      </footer>
    </div>
  );
}

export default LandingPage;
