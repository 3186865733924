// src/pages/Services.js
import React from 'react';
import NavBar from '../components/NavBar';
import servicesImage1 from '../assets/services-page1.jpeg';
import servicesImage2 from '../assets/services-page2.jpg';
import servicesImage3 from '../assets/services-page3.jpg';
import './../styles/Services.css';

function SkilledMedicalCare() {
  return (
    <div>
      <NavBar/>
      <div className='services-container'>
        <div className='split-screen'>
          <div className='text-content'>
            <h2>Skilled Medical Care</h2>
            <div className="term">Skilled Nursing</div>
            <div className="description">Professional nursing care tailored to patient needs.</div>

            <div className="term">Home Health Aide</div>
            <div className="description">Assistance with daily living activities.</div>

            <div className="term">Occupational Therapy</div>
            <div className="description">Assistance in regaining independence in daily activities.</div>

            <div className="term">Speech Therapy</div>
            <div className="description">Support for speech and communication challenges.</div>

            <div className="term">Licensed Medical Social Worker</div>
            <div className="description">Help with emotional, social, and financial challenges.</div>

            <div className="term">Physical Therapy</div>
            <div className="description">Rehabilitation to improve mobility and physical function.</div>
          </div>
          <div className='image-content'>
            <img src={servicesImage2} alt='Skilled Medical Care' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkilledMedicalCare;
