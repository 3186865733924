// src/pages/Services.js
import React from 'react';
import NavBar from '../components/NavBar';
import servicesImage1 from '../assets/services-page1.jpeg';
import servicesImage2 from '../assets/services-page2.jpg';
import servicesImage3 from '../assets/services-page3.jpg';
import './../styles/Services.css';

function InHomeServices() {
  return (
    <div>
      <NavBar/>
      <div className='services-container'>
        <div className='split-screen'>
          <div className='text-content'>
            <h2>In-Home Services</h2>
            <div className="term">Certified Visiting Physicians</div>
            <div className="description">Doctors who visit patients at home.</div>

            <div className="term">Visiting Podiatrist</div>
            <div className="description">Specialized foot care services at home.</div>

            <div className="term">Mobile X-Ray</div>
            <div className="description">On-site radiographic imaging.</div>
          </div>
          <div className='image-content'>
            <img src={servicesImage1} alt='In-Home Services' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InHomeServices;
