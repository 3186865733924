// src/components/MobileNavBar.js
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "./../assets/white-logo-no-bg.png";
import './../styles/MobileNavBar.css';

const MobileNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <nav className="mobile-nav" ref={navRef}>
      <div className='logo-container'>
        <img src={logo} alt="Logo" className='logo'/>
      </div>
      <div className="hamburger-menu" onClick={handleToggle}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={`mobile-nav-menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <Link to="/" className="mobile-nav-link">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className='mobile-nav-link'>
              About Us
            </Link>
          </li>
          <li>
            <Link to="/contact" className='mobile-nav-link'>
              Contact Us
            </Link>
          </li>
          <li>
            <Link to="/location" className='mobile-nav-link'>
              Location
            </Link>
          </li>
          <li className="dropdown">
            <span className="mobile-nav-link">Services</span>
            <div className="dropdown-content">
              <Link to="/services/skilled-medical-care" className="dropdown-item">
                Skilled Medical Care
              </Link>
              <Link to="/services/non-medical-care" className="dropdown-item">
                Non-Medical Care
              </Link>
              <Link to="/services/in-home-services" className="dropdown-item">
                In-Home Services
              </Link>
              <Link to="/services/specialized-programs" className="dropdown-item">
                Specialized Programs
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MobileNavBar;
