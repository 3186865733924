// src/pages/Services.js
import React from 'react';
import NavBar from '../components/NavBar';
import servicesImage2 from '../assets/home-care-options2.jpg';
import './../styles/Services.css';

function NonMedicalCare() {
  return (
    <div>
      <NavBar/>
      <div className='services-container'>
        <div className='split-screen'>
          <div className='text-content'>
            <h2>Non-Medical Care</h2>
            <h4>Our private duty care team is dedicated to any in home needs your loved ones might need outside of services refered by a doctor.
               This personal assistance service can include any of the following:  </h4>
            <div className="term">Medication Reminders</div>
            <div className="description">Our team will ensure that your loved ones take the right medicine at the right time every day</div>
            <div className="term">Companionship</div>
            <div className="description">Our team can provide a kind face and a sense of company for loved ones that spend long stretches of time alone</div>
            <div className="term">Meal Preparation</div>
            <div className="description">To guarantee that your loved ones are eating regular and healthy meals, our team can help prepare regular warm meals for your loved one</div>
            <div className="term">Light Housekeeping</div>
            <div className="description"> Our team can provide a clean and hospitible home for your loved ones through light housekeeping to fill the gaps in ability that your loved one might be facing</div>
            <div className="term">Bathing & Grooming</div>
            <div className="description">Our team will assist in your loved one's hygiene through our bathing and grooming services</div>
            <div className="term">Assistance with Ambulation</div>
            <div className="description">If you have a loved one with any mobility problems our team can assist with any trouble walking around the home</div>

            <h4>Our dedicated and trained staff are licensed and bonded. This list serves as a brief overview of what we can do but if you have any specific needs or questions please contact us and we will find a solution that works best for your loved ones and their needs!</h4>

            <a href='/contact' className='service-btn'>Contact Us</a>
          </div>
          <div className='image-content'>
            <img src={servicesImage2} alt='Skilled Medical Care' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NonMedicalCare;
