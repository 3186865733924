// src/pages/Services.js
import React from 'react';
import NavBar from '../components/NavBar';
import servicesImage3 from '../assets/services-page3.jpg';
import './../styles/Services.css';

function SpecializedPrograms() {
  return (
    <div>
      <NavBar />
      <div className='services-container'>
        <div className='split-screen'>
          <div className='text-content'>
            <h2>Specialized Programs</h2>

            <div className="term">Transition Care / Post-Surgical Care</div>
            <div className="description">Support for patients transitioning from hospital to home after surgery.</div>

            <div className="term">Wound Care and Dressing Changes</div>
            <div className="description">Expert care for wounds and regular dressing changes.</div>

            <div className="term">Chronic Care Management</div>
            <div className="description">Comprehensive management of chronic health conditions.</div>

            <div className="term">CHF Management</div>
            <div className="description">Specialized care for patients with Congestive Heart Failure.</div>

            <div className="term">Catheter/Foley Care</div>
            <div className="description">Professional care for catheter management.</div>

            <div className="term">UTI Management / Incontinence Program</div>
            <div className="description">Support for urinary tract infections and incontinence issues.</div>

            <div className="term">Diabetic Management</div>
            <div className="description">Comprehensive diabetes care and monitoring.</div>

            <div className="term">Medication Management</div>
            <div className="description">Ensuring proper medication usage and adherence.</div>

            <div className="term">PT/INR Monitoring</div>
            <div className="description">Regular monitoring of blood clotting times.</div>

            <div className="term">COPD Program</div>
            <div className="description">Specialized care for Chronic Obstructive Pulmonary Disease patients.</div>

            <div className="term">Intravenous (IV) Care / IV Therapy</div>
            <div className="description">Administration of IV therapies in the comfort of home.</div>

            <div className="term">Injections</div>
            <div className="description">Licensed nurses to administer necessary injections.</div>

            <div className="term">Patient and Caregiver Education</div>
            <div className="description">Training for patients and caregivers to manage health conditions effectively.</div>

            <div className="term">In-Home Lab Draw</div>
            <div className="description">Convenient in-home lab tests with next-day results.</div>
          </div>

          <div className='image-content'>
            <img src={servicesImage3} alt='Specialized Programs' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecializedPrograms;
