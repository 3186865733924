// src/components/NavBar.js
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from "./../assets/white-logo-no-bg.png";
import './../styles/DesktopNavBar.css';

const DesktopNavBar = () => {
  return (
    <nav className='desktop-nav'>
        <div className="left-links">
            <ul className='desktop-nav-menu left-links'>
                <li>
                    <Link to="/" className="desktop-nav-link">
                        Home
                    </Link>
                </li>
                <li>
                    <Link to="/about" className='desktop-nav-link'>
                        About Us
                    </Link>
                </li>
                <li>
                    <Link to="/location" className='desktop-nav-link'>
                        Location
                    </Link>
                </li>
                <li className="dropdown">
                    <span className="desktop-nav-link">Services</span>
                    <div className="dropdown-content">
                        <Link to="/services/skilled-medical-care" className="dropdown-item">
                            Skilled Medical Care
                        </Link>
                        <Link to="/services/non-medical-care" className="dropdown-item">
                            Non-Medical Care
                        </Link>
                        <Link to="/services/in-home-services" className="dropdown-item">
                            In-Home Services
                        </Link>
                        <Link to="/services/specialized-programs" className="dropdown-item">
                            Specialized Programs
                        </Link>
                    </div>
                </li>
            </ul>
        </div>
        <div className='desktop-logo-container'>
            <img src={logo} alt="Logo" className='desktop-logo'/>
        </div>
        <div className='right-links'>
            <ul className="desktop-nav-menu">
                <li>
                    <Link to="/contact" className='desktop-nav-link'>
                        Contact Us
                    </Link>
                </li>
            </ul>
        </div>
    </nav>
  );
};

export default DesktopNavBar;
