// src/pages/ContactUsPage.js
import React from 'react';
import NavBar from '../components/NavBar';
import './../styles/ContactUsPage.css';

function ContactUsPage() {
  return (
    <div>
        <NavBar />
        <div className="contact-us-container">
            <h2>Contact Us</h2>
            <div className="inquiries-section">
                <h3>For inquiries:</h3>
                <p>If you have any questions or need assistance, please don't hesitate to reach out to us.</p>
                <p><b>Phone:</b> (832) 328-0179</p>
                <p><b>Email:</b> info@hcareoptions.com</p>
                <p><b>Fax:</b> (832) 218-7179</p>
            </div>
            <form action="mailto:info@hcareoptions.com" method="post" enctype="text/plain">
                <label htmlFor="name">Full Name:</label>
                <input type="text" id="name" name="name" required />
                
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />
                <label htmlFor="phone">Phone Number:</label>
                <input type="phone" id="phone" name="phone" required />
                
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" required></textarea>
                
                <input type="submit" value="Submit" />
            </form>
        </div>
    </div>
  );
}

export default ContactUsPage;
