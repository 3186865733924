import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactUsPage from './pages/ContactUsPage';
import Services from './pages/Services';
import SkilledMedicalCare from './pages/SkilledMedicalCare';
import NonMedicalCare from './pages/NonMedicalCare';
import InHomeServices from './pages/InHomeServices';
import SpecializedPrograms from './pages/SpecializedPrograms';
import ServiceArea from './pages/ServiceArea';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/services/skilled-medical-care" element={<SkilledMedicalCare/>}/>
          <Route path="/services/non-medical-care" element={<NonMedicalCare/>}/>
          <Route path="/services/in-home-services" element={<InHomeServices/>}/>
          <Route path="/services/specialized-programs" element={<SpecializedPrograms/>} />
          <Route path="/location" element={<ServiceArea/>}/>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
